import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => {
  useEffect(() => {
    window.prismic = {
      endpoint: 'https://myrmedia.cdn.prismic.io/api/v2'
    };
  });

  return (
    <Layout>
      <SEO title="404: Not found" />
      <Helmet>
        <meta charset="utf-8" />
        <title>404: Not found</title>
        <link rel="canonical" href="myrmedia.ltd" />
      </Helmet>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  );
};

export default NotFoundPage;
